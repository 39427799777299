import "./App.scss";
import Logo from "./images/logo1.png";
import Gym from "./images/gym.jpeg";

function App() {
  return (
    <div className="App">
      <div className="top">
        <nav>
          <div className="nav-wrapper">
            <a href="#" className="brand-logo">
              <img src={Logo} alt="logo" />
            </a>
            <ul id="nav-mobile" className="right hide-on-med-and-down">
              <li>
                <a href="#gym">About Us</a>
              </li>
              <li><a target="_blank" href="https://rhinofit.store/?wpam_id=11">Sports Nutrition</a></li>
              <li>
                <a href="#location">Location</a>
              </li>
              <li>
                <a style={{
                  fontWeight:'800',
                  color:'#FF5398'
                }} href="https://my.rhinofit.ca/store/index/1100b05f">Join Today!</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container">
          <h2>It's Time For <span style={{
            color:'#0BA5F9'
          }}>YOUR</span> <span style={{
            color:'#9B2DD2'
          }}>PRIME!</span></h2>
          <h4>
            Looking to join a fun, supportive and welcoming gym in Oxford?
            Looking for something a little different? Then Prime Fitness is
            exactly what you are looking for. We are a small, family owned gym
            with a big heart.
          </h4>
          <div className="buttonContainer">
            <a href="https://my.rhinofit.ca/store/index/1100b05f" className="btn primary">
              Join Today!
            </a>
          </div>
        </div>
      </div>

      <div className="site-container">
        <div className="box">
          <div className="left">
            <h2 id="gym">Mission Statement</h2>
            <p>
              Prime Fitness is a small family owned gym in Oxford, Ohio. We
              offer a clean, respectful and open environment. We have a wide
              variety of equipment that can cater to each type of workout
              regimen and accommodates all fitness levels.
              <br />
              <br />
              Our mission statement is to provide a fun and inviting environment
              for each individual to maximize their strengths, physically and
              mentally, and to evolve into their PRIME!
            </p>
          </div>
          <div className="right">
            <img src={Gym} alt="gym" />
          </div>
        </div>
      </div>

      <div className="box-pink">
        <div className="container">
          <div className="left">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1546.535352375221!2d-84.74536901685661!3d39.50474249091135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1662655444403!5m2!1sen!2sus"
              width="600"
              height="350"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="right">
            <h2 id="location">Location</h2>

            <p>
              507 S College Ave, Oxford, OH 45056
              <br />
              PH: (513) 255-9101
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
